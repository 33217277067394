export default {
  label: {
    ok: 'ठीक है',
    confirm: 'कन्फर्म करें',
    cancel: 'कैंसिल करें',
    download: 'डाउनलोड',
    submit: 'सबमिट करें',
    search: 'सर्च',
    recharge: 'रिचार्ज',
    balance: 'बैलेंस',
  },
  tip: {
    success: 'सफल',
  },
  text: {},
  defaultPage: {
    noMore: 'और डेटा नहीं है',
  },
  home: {
    scratchOff: 'स्क्रैच ऑफ',
    copied: 'सफलतापूर्वक कॉपी किया गया, कृपया दोस्तों के साथ शेयर करें',
    tip: {
      beforDownload: 'कृपया रजिस्ट्रेशन या लॉगिन करें',
    },
  },
  alert: {
    logout: 'लॉग आउट',
    sureLogout: 'क्या आप वाकई लॉगआउट करना चाहते हैं?',
  },
  warning: {
    unopen: 'अभी तक चालू नहीं किया गया है',
  },
  notification: {
    title: 'नोटिफिकेशन',
    announcements: 'घोषणाएँ',
    systemMessage: 'सिस्टम संदेश',
  },
  vip: {
    currentLevel: 'वर्तमान लेवल',
    collected: 'इकट्ठा करने के लिए',
    finished: 'समाप्त करने के लिए',
    received: 'प्राप्त किया',
    recharge: 'रिचार्ज',
    move: 'अगले लेवल तक पहुंचने के लिए बहुत कुछ',
    title: 'वीआईपी',
    table: {
      level: 'वीआईपी लेवल',
      reward: 'रिवॉर्ड',
      bouns: 'बोनस',
      spin: 'स्पिन',
      completed: 'पुरा होना',
      incomplete: 'अधूरा',
    },
    success: 'सफलतापूर्वक प्राप्त हुआ',
  },
  referral: {
    copy: 'कॉपी करें',
    invite: 'इनवाइट करें',
    copied: 'सफलतापूर्वक कॉपी किया गया',
  },
  luckyspin: {
    add: 'जोड़ें',
    spin: 'स्पिन',
    won: 'जीत',
    winner: 'बड़े विजेता',
    mySpin: 'मेरी स्पिन',
    free: 'फ्री',
    myfree: 'मेरा फ्री',
    morefree: 'और फ्री',
    vip: 'वीआईपी',
    usefreefirst: 'कृपया पहले फ्री वाले का उपयोग करें',
  },
  'error-pages': {
    refresh: {
      label: {
        title: 'कुछ गलती हुई है!',
        'sub-title': 'कृपया पेज को रीफ्रेश करें',
        'try-again': 'फिर कोशिश करें',
      },
    },
    'कोई डेटा नहीं': {
      label: {
        title: 'कोई डेटा नहीं',
      },
    },
    'नहीं मिला': {
      label: {
        title: 'वर्तमान नेटवर्क सिग्नल खराब है',
        'sub-title': 'कृपया नेटवर्क की जांच करें',
      },
    },
    'कोई रिजल्ट नहीं': {
      label: {
        title: 'कोई रिजल्ट नहीं मिला!',
      },
    },
  },
  share: {
    copy: 'कॉपी',
    invite: 'इनवाइट करें',
    'copy-success': 'सफलतापूर्वक कॉपी किया गया',
    copied: 'सफलतापूर्वक कॉपी किया गया',
  },
  casino: {
    big: 'बड़ी',
    win: 'जीत',
    detail: {
      title: 'भाग्यशाली खिलाड़ियों को बधाई',
    },
    winPrize: 'जीत का प्राइज',
    playnow: 'अभी खेलें',
  },
  login: {
    label: {
      login: 'लॉग इन',
      skip: 'छोड़ें',
      sign: 'साइन अप करें',
      next: 'अगला',
      'select-region': 'क्षेत्र चुनें',
      'otp-login': 'ओटीपी लॉगिन',
      'password-login': 'पासवर्ड लॉगिन',
      'get-otp': 'ओटीपी प्राप्त करें',
      'set-password': 'पासवर्ड सेट करें',
    },
    tip: {
      'no-phone': 'फ़ोन नंबर दर्ज करें',
      'no-password': 'पासवर्ड डालें',
      'no-otp': 'ओटीपी दर्ज करें',
      phone: 'फ़ोन नंबर दर्ज करें',
      password: 'पासवर्ड डालें(6-18)',
      passwordRule:
        'पासवर्ड 8 से 16 कैरेक्टर में होना चाहिए और इसमें अंक और अक्षर दोनों होने चाहिए',
      'पासवर्ड कंफर्म करें': 'पासवर्ड कंफर्म करें',
      forgot: 'पासवर्ड भूल गए?',
      'new-account': 'अकाउंट की ज़रूरत है?',
      'sing-in': 'साइन अप करें',
      otp: 'ओटीपी डालें',
      'referral-code': 'रेफर कोड',
      confrim18: 'हाँ मेरी उम्र 18 वर्ष से अधिक है',
      'allow-notify':
        'हमें इस मोबाइल नंबर के माध्यम से आपको जीत की जानकारी देने की अनुमति दें।',
      'has-account': 'पहले से रजिस्टर्ड है?',
      'sign-in': 'लॉगिन',
      page18: 'हाँ मेरी उम्र 18 वर्ष से अधिक है',
      notify:
        'हमें इस मोबाइल नंबर के माध्यम से आपको जीत की जानकारी देने की अनुमति दें।',
      'pwd-dif': 'दोनों पासवर्ड समान नहीं है',
      modified: 'पासवर्ड सफलतापूर्वक बदल दिया गया. कृपया फिर से लॉगिन करें',
      'game-not-active':
        'माफ़ कीजिये, गेम अभी मेंटेनेंस में है। कृपया बाद में पुन: प्रयास करें।',
    },
  },
  paidSuccess: {
    label: {
      title: 'पेमेंट',
      subTitle: 'सफलतापूर्वक पे कर दिया गया',
      viewOrder: 'आर्डर देखें',
      backHome: 'होम पर जाएं',
    },
    tip: {
      msg: 'आपकी टिकटें सफलतापूर्वक खरीद ली गई हैं। कृपया ड्रा के समय का ध्यान रखें और रिजल्ट की जांच करें।',
    },
  },
  splash: {
    tip: {
      checkingResources: 'संसाधनों की जाँच हो रही है...',
      checkingVersion: 'वर्ज़न की जाँच हो रही है...',
      downloading: 'संसाधनों को डाउनलोड किया जा रहा है...',
      resolve:
        'संसाधन समायोजित किये जा रहे हैं (इसके दौरान आपका इंटरनेट डेटा खर्च नहीं होगा)',
      alertTitle: 'अपडेट उपलब्ध है',
      restart: 'रीस्टार्ट करें',
      alertContent:
        'अपडेट उपलब्ध है। अपडेट लागू करने के लिए ऐप को रीस्टार्ट किया जा रहा है...',
    },
  },
  recharge: {
    tip: {
      easyPaymentError: 'असफल हुआ, कृपया कोई अन्य भुगतान विधि चयन करें',
    },
  },
  'recharge-page': {
    currentMethod: 'वर्तमान विधि',
    label: {
      records: 'रिकॉर्ड्स',
      min: 'मिनिमम',
      max: 'अधिकतम',
      channel: 'रिचार्ज चैनल',
      enter: 'रिचार्ज राशि दर्ज करें',
    },
    tip: {
      payMethodTip:
        'कृपया वर्तमान विधि असफल होने पर दूसरे विधि में स्विच करें।',
      'money-error': 'कृपया सही राशि दर्ज करें',
      'paymethod-error': 'कृपया भुगतान विधि का चयन करें',
    },
  },
  datePicker: {
    label: {
      select: 'तारीख चुनें',
    },
  },
  rebate: {
    tabs: {
      all: 'सभी',
      finish: 'पूरा करने के लिए',
      collect: 'कलेक्ट करने के लिए',
      received: 'प्राप्त हुआ',
    },
    title: 'कैशबैक',
    filter: 'फ़िल्टर',
    undone: 'अपूर्ण',
    unclaimed: 'अनदेखा',
    completed: 'पूर्ण हुआ',
    unfinished: 'अपूर्ण',
    receive: 'प्राप्त करें',
    received: 'प्राप्त हुआ',
    today: 'आज',
    yesterday: 'बीता हुआ कल',
    betting: 'अनुमानित बेटिंग：',
    rebate: 'अनुमानित कैशबैक：',
    congratulations: 'बधाई हो！',
    'get-bonus': 'बोनस प्राप्त करें',
    rule: {
      betting: 'बेटिंग बोनस:',
      'betting-detail':
        '{{name}} प्लेयर को बेटिंग अमाउंट के अनुसार कैशबैक मिलेगा। जितना अधिक आप बेट करेंगे, उतना ही आपको कैशबैक मिलेगा।',
      activity: 'गतिविधि विवरण:',
      'activity-detail':
        '{{name}} सुपर बोनस कैशबैक सुविधा आपके लिए ओपन है। अब कोई फर्क नहीं पड़ता कि आप जीतते हैं या हारते हैं, आपको रोजाना बोनस मिलेगा।',
      actrule: 'गतिविधि नियम:',
      'actrule-detail':
        'खिलाड़ी प्रतिदिन एक बेटिंग कैशबैक बोनस क्लैम कर सकते हैं। बोनस क्लैम करने का समय हर दिन 12:30AM के बाद है.',
      precaution: 'सावधानियां:',
      'precaution-detail-1':
        '-केवल योग्य {{name}} मेंबर इन एक्टिविटी में भाग ले सकते हैं। प्रत्येक सदस्य को रिबेट बोनस का दावा करने के लिए सिंगम अकाउंट में, अकाउंट डिटेल डालना होगा। - किसी भी उपयोगकर्ता जो गैरकानूनी गतिविधियों में शामिल है, उसे सख्ती से निकाल दिया जाएगा',
      'precaution-detail-2':
        '- एक ही फ़ोन नंबर, बैंक खाता नंबर, ईमेल और आईपी एड्रेस को कई अकाउंट के लिए उपयोग नहीं किया जा सकता है',
      'precaution-detail-3':
        '- {{name}} को खातों को फ़्रीज़ करने या भुगतान रद्द करने का पूरा अधिकार है यदि उपयोगकर्ता या ग्रुप द्वारा बोनस का लाभ उठाने के लिए गैरकानूनी गतिविधि की जाती है तो। (अंतिम बोनस निर्णय सिंगम का है)',
    },
  },
  wallet: {
    title: 'मेरा वॉलेट',
    total: 'कुल वॉलेट',
    recharge: 'रिचार्ज',
    main: 'मेन वॉलेट',
    third: 'थर्ड पार्टी वॉलेट',
    transfer: 'मेन वॉलेट ट्रांसफर',
    translation: 'ट्रांसलेशन: 5 सेकंड',
    transComplete: 'परिवर्तन पूर्ण हुआ',
    activities: 'रिचार्ज और दावा गतिविधियाँ',
    transferNav: {
      recharge: 'रिचार्ज',
      withdraw: 'विड्राल',
      transfer: 'ट्रांसफर',
    },
  },
  promotion: {
    title: 'प्रमोशन',
    go: 'जाएँ',
    playNow: 'अभी खेलें',
    view: 'देखें',
    detail: 'गतिविधि विवरण',
    join: 'शामिल हों',
    checkin: 'चेक इन',
    checkinDescription: 'प्रतिदिन चेक इन करके बहुत सारे पुरस्कार प्राप्त करें',
  },
};
